import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import {firestoreConnect, isEmpty} from "react-redux-firebase";
import {connect} from "react-redux";
import {compose} from "recompose";
import {DataGrid, GridToolbar} from '@material-ui/data-grid';
import _ from 'lodash';
import moment from "moment";
import Button from "@material-ui/core/Button";
import {Checkbox} from "@material-ui/core";
import AllInboxIcon from "@material-ui/icons/AllInbox";

function Receipts(props) {
    const {classes, receipts} = props;

    if (isEmpty(receipts)) {
        return (<b>Loading ...</b>);
    }

    const columns = [
        {
            field: 'receiptDate',
            headerName: 'Belegdatum',
            type: 'date',
            valueFormatter: ({ value }) => moment(value).format("DD.MM.YYYY"),
            width: 130
        },
        {
            field: 'imgUrl',
            headerName: 'Bild',
            type: 'string',
            renderCell: (params) => <img height="50" width="50" src={params.value} />,
            width: 85,
            filterable: false
        },
        {
            field: 'merchant',
            headerName: 'Händler',
            width: 270
        },
        {
            field: 'amount',
            headerName: 'Betrag',
            type: 'number',
            valueFormatter: ({ value }) => `${value} €`,
            width: 120
        },
        {
            field: 'isInbox',
            headerName: 'Inbox',
            type: 'string',
            renderCell: (params) => (
                <Checkbox icon={<AllInboxIcon color={"disabled"}/>}
                          checkedIcon={<AllInboxIcon color={'primary'}/>}
                          disabled
                          checked={params.value}
                          name="isInbox" />
            ),
            width: 95,
            filterable: false
        },
        {
            field: 'id',
            headerName: 'Id',
            type: 'string',
            renderCell: (params) => (
                <Button href={`./receipt/${params.value}`} color="primary">
                    Bearbeiten
                </Button>
            ),
            width: 150,
            filterable: false
        },
        {
            field: 'date',
            headerName: 'Upload Datum',
            type: 'date',
            valueFormatter: ({ value }) => moment(value).format("DD.MM.YYYY HH:mm:ss"),
            width: 180
        },

    ];

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.titleBar} position="static" elevation={0}>
                <Typography color="inherit" variant="h6" component="h1" align="center">
                    Belege
                </Typography>
            </AppBar>
            <div className={classes.contentWrapper}>
                <Grid item xs={12} sm container spacing={2} className={classes.loginWrapper}>
                    <div style={{height: 900, width: '100%'}}>
                        <DataGrid
                            pageSize={25} rowsPerPageOptions={[25, 50, 100]}
                            pagination
                            density="compact"
                            rows={_.values(receipts)}
                            columns={columns}
                            components={{
                                Toolbar: GridToolbar,
                            }}
                        />
                    </div>
                </Grid>
            </div>
        </Paper>
    );
}

Receipts.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
    paper: {
        maxWidth: 1236,
        margin: 'auto',
        overflow: 'hidden',
    },
    titleBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '40px 16px',
        maxHeight: '900px',
    },
});

const enhance = compose(
    withStyles(styles),
    firestoreConnect((props) => [
        {
            collection: `accounts/${props.accountId}/receipts`,
            storeAs: 'receipts'
        },
    ]),
    connect((state, props) => ({
        receipts: state.firestore.data.receipts,
    })),
);

export default enhance(Receipts);
