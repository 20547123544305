import React, {useEffect, useState} from 'react';
import {compose} from "recompose";
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import {
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Divider from "@material-ui/core/Divider";
import {useInput} from "../../hooks/useInput";
import Button from "@material-ui/core/Button";
import {useFirestore} from "react-redux-firebase";
import {useSelector} from "react-redux";
import moment from "moment";
import {useHistory} from "react-router-dom";

const InboxReceipt = (props) => {
    const history = useHistory();
    const {classes, receipt} = props;
    const firestore = useFirestore();
    const account = useSelector(state => state.account);

    if(receipt == null){
        history.push(`/a/${account.accountId}/inbox`);
    }

    const pathToReceipts = `/accounts/${account.accountId}/receipts`;

    const [amountProps, resetAmount] = useInput(receipt.amount);
    const [merchantProps, resetMerchant] = useInput(receipt.merchant);
    const [receiptDate, resetReceiptDate] = useState(null);
    const [isInbox, resetIsInbox] = useState(!receipt.isInbox)

    useEffect(() => {
        resetAmount(receipt.amount);
        resetMerchant(receipt.merchant);
        resetReceiptDate(receipt.date ? receipt.date : receipt.receiptDate);
        resetIsInbox(!receipt.isInbox)
    }, [receipt]);

    const updateData = e => {
        e.preventDefault();
        const receiptDateStr = moment(receiptDate).format('YYYY-MM-DD')
        return firestore.update(
            `${pathToReceipts}/${receipt.id}`, {
                ...receipt,
                amount: amountProps.value,
                merchant: merchantProps.value,
                receiptDate: receiptDateStr,
                isInbox: !isInbox
            });
    }

    return (
        <Grid item xs={12} sm container spacing={2} className={classes.loginWrapper}>
            <Grid item xs={7} container direction="column" alignItems="center">
                <a href={receipt.imgUrl} target="_blank">
                    <img className={classes.receiptImage} alt="complex" src={receipt.imgUrl}/>
                </a>
            </Grid>
            <Divider variant="middle" orientation="vertical" flexItem light={true}/>
            <Grid item xs={4} container direction="column" alignItems="left">
                <form id={receipt.id}
                      className={classes.form}
                      noValidate autocomplete="off"
                      onSubmit={updateData}
                >
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Save
                    </Button>
                    <br/><br/>
                    <FormControl fullWidth className={classes.margin}>
                        <InputLabel htmlFor="standard-adornment-amount">Betrag</InputLabel>
                        <Input id="amount" name="amount" {...amountProps}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                        />
                    </FormControl>
                    <br/>
                    <FormControl fullWidth className={classes.margin}>
                        <InputLabel htmlFor="standard-adornment-merchant">Händler</InputLabel>
                        <Input id="merchant" name="merchant" {...merchantProps} />
                    </FormControl>
                    <KeyboardDatePicker
                        value={receiptDate}
                        onChange={resetReceiptDate}
                        disableToolbar
                        variant="inline"
                        format="DD.MM.yyyy"
                        margin="normal"
                        label="Belegdatum"
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isInbox}
                                onChange={resetIsInbox}
                                name="checkedB"
                                color="primary"
                                disabled
                            />
                        }
                        label="Erledigt?"
                    />
                    <br/><br/> <br/><br/>
                    {receipt.id}
                    <br/><br/>
                    {receipt.text}
                </form>
            </Grid>
        </Grid>
    );
}

InboxReceipt.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
    receiptImage: {
        width: 528,
    },
    form: {
        margin: "normal"
    },
    margin: {
        margin: "normal"
    }
});

const enhance = compose(
    withStyles(styles)
);
export default enhance(InboxReceipt);
