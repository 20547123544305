import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

const Footer = (props) => {
    const {classes} = props;
    return(
        <footer className={classes.footer}>
            <b>Copyright</b>
        </footer>
    );
}

Footer.propTypes= {
    classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
    footer: {
        padding: theme.spacing(2),
        background: '#eaeff1',
    },
});

export default withStyles(styles)(Footer);
