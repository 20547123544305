import React, {useState} from 'react';
import Navigator from "../components/Navigator";
import Header from "../components/Header";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Hidden from "@material-ui/core/Hidden";
import {connect, useSelector} from "react-redux";
import {compose} from 'recompose';
import {firestoreConnect, isEmpty} from 'react-redux-firebase'
import Footer from "../components/Footer";
import {
    Redirect,
    Switch,
    Route,
    useParams,
    useRouteMatch
} from "react-router-dom";
import Inbox from "../components/inbox/Inbox";
import Receipts from "../components/receipts/Receipts";

function HomePage(props) {
    const {classes, accountList, auth} = props;
    const [mobileOpen, setMobileOpen] = useState(true);
    let {path, url} = useRouteMatch();
    let {accountId} = useParams();

    if (isEmpty(accountList)) {
        return (<b>Loading ...</b>);
    }
    const account = accountList[accountId];

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <div className={classes.root}>
            <nav className={classes.drawer}>
                <Hidden smUp implementation="js">
                    <Navigator
                        PaperProps={{style: {width: drawerWidth}}}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                    />
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Navigator
                        url={url}
                        accountName={account.accountName}
                        PaperProps={{style: {width: drawerWidth}}}/>
                </Hidden>
            </nav>
            <div className={classes.app}>
                <Header onDrawerToggle={handleDrawerToggle} auth={auth}/>
                <main className={classes.main}>
                    <Switch>
                        <Route exact path={path}>
                            <Redirect to={`${url}/inbox`}/>
                        </Route>
                        <Route path={`${path}/inbox`}>
                            <Inbox accountId={accountId}/>
                        </Route>
                        <Route path={`${path}/receipts`}>
                            <Receipts accountId={accountId}/>
                        </Route>
                    </Switch>
                </main>
                <Footer/>
            </div>
        </div>
    );
}

const drawerWidth = 256;

const styles = (theme) => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
        flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    app: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        flex: 1,
        padding: theme.spacing(6, 4),
        background: '#eaeff1',
    },
});

HomePage.propTypes = {
    classes: PropTypes.object.isRequired,
    //userAccount: PropTypes.object.isRequired,
};

const enhance = compose(
    withStyles(styles),
    firestoreConnect((props) => [
        {
            collection: 'accounts',
            where: [['accountOwners', 'array-contains', props.userId]],
            storeAs: 'accountList',
        },
    ]),
    connect((state, props) => ({
        accountList: state.firestore.data.accountList,
        auth: state.firebase.auth,
    })),
);

export default enhance(HomePage);
