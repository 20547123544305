import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles, useTheme } from '@material-ui/core/styles';
import {firestoreConnect, isEmpty} from "react-redux-firebase";
import {connect} from "react-redux";
import MobileStepper from '@material-ui/core/MobileStepper';
import {compose} from "recompose";
import _ from 'lodash';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import InboxReceipt from "./InboxReceipt";

function Inbox(props) {
    const theme = useTheme();
    const { classes, receiptsInbox} = props;
    const [activeStep, setActiveStep] = useState(0);

    if (isEmpty(receiptsInbox)) {
        return (<b>Loading ...</b>);
    }
    const receiptsInboxKeys = _.keys(receiptsInbox)
    const maxSteps = receiptsInboxKeys.length;


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.titleBar} position="static" elevation={0}>
                <Typography color="inherit" variant="h6" component="h1" align="center">
                    Inbox
                </Typography>
            </AppBar>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <b></b>
                        </Grid>
                        <Grid item xs>
                            <MobileStepper
                                steps={maxSteps}
                                position="static"
                                variant="text"
                                activeStep={activeStep}
                                nextButton={
                                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                        Next
                                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                        Back
                                    </Button>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <b></b>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div className={classes.contentWrapper}>
                <InboxReceipt receipt={receiptsInbox[receiptsInboxKeys[activeStep]]}/>
            </div>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <b></b>
                        </Grid>
                        <Grid item xs>
                            <MobileStepper
                                steps={maxSteps}
                                position="static"
                                variant="text"
                                activeStep={activeStep}
                                nextButton={
                                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                        Next
                                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                        Back
                                    </Button>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <b></b>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Paper>
    );
}

Inbox.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
    paper: {
        maxWidth: 1236,
        margin: 'auto',
        overflow: 'hidden',
    },
    titleBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '40px 16px',
    },
});

const enhance = compose(
    withStyles(styles),
    firestoreConnect((props) => [
        {
            collection: `accounts/${props.accountId}/receipts`,
            where: [['isInbox', '==', true]],
            storeAs: 'receiptsInbox'
        },
    ]),
    connect((state, props) => ({
        receiptsInbox: state.firestore.data.receiptsInbox,
    })),
);

export default enhance(Inbox);
