import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {compose} from 'recompose';
import {useSelector, connect, useDispatch} from 'react-redux';
import {isLoaded, isEmpty} from 'react-redux-firebase'

import HomePage from './pages/HomePage';
import PrivateRoute from './utils/PrivateRoute';
import PropTypes from 'prop-types';
import {createMuiTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Logout from "./components/auth/Logout";
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import PasswordReset from "./components/auth/PasswordReset";
import {setAccountId} from "./redux/actions";

//import './App.css';

function CostfitApp(props) {
    const {classes, auth, profile} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const dispatch = useDispatch();
    const defaultAccountId = useSelector(state => state.account.accountId);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    if (!isLoaded(auth) && isEmpty(profile)) {
        return (<b>Loading ...</b>);
    }

    if(!isEmpty(profile)){
        dispatch(setAccountId(profile.defaultAccountId));
    }

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                <CssBaseline/>
                <Switch>
                    <Route path="/logout">
                        <Logout/>
                    </Route>
                    <Route path="/login">
                        <SignIn/>
                    </Route>
                    <Route path="/register">
                        <SignUp/>
                    </Route>
                    <Route path="/password">
                        <PasswordReset/>
                    </Route>
                    <PrivateRoute path="/a/:accountId">
                        <HomePage userId={auth.uid} />
                    </PrivateRoute>
                    <PrivateRoute excat path="/">
                        <Redirect to={`/a/${defaultAccountId}`} />
                    </PrivateRoute>
                </Switch>
            </div>
        </ThemeProvider>
    );
}

let theme = createMuiTheme(
    {
        palette: {
            primary: {
                light: '#63ccff',
                main: '#009be5',
                dark: '#006db3',
            },
        },
        typography: {
            h5: {
                fontWeight: 500,
                fontSize: 26,
                letterSpacing: 0.5,
            },
        },
        shape: {
            borderRadius: 8,
        },
        props: {
            MuiTab: {
                disableRipple: true,
            },
        },
        mixins: {
            toolbar: {
                minHeight: 48,
            },
        },
    });

theme = {
    ...theme,
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: '#18202c',
            },
        },
        MuiButton: {
            label: {
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
                '&:active': {
                    boxShadow: 'none',
                },
            },
        },
        MuiTabs: {
            root: {
                marginLeft: theme.spacing(1),
            },
            indicator: {
                height: 3,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                backgroundColor: theme.palette.common.white,
            },
        },
        MuiTab: {
            root: {
                textTransform: 'none',
                margin: '0 16px',
                minWidth: 0,
                padding: 0,
                [theme.breakpoints.up('md')]: {
                    padding: 0,
                    minWidth: 0,
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: theme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: '#404854',
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {
                color: 'inherit',
                marginRight: 0,
                '& svg': {
                    fontSize: 20,
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32,
            },
        },
    },
};

const styles = (theme) => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
    },

});

CostfitApp.propTypes = {
    classes: PropTypes.object.isRequired,
};


const enhance = compose(
    connect(
        // Map redux state to component props
        ({firebase: {auth, profile}}) => ({
            auth,
            profile
        }),
    ),
    withStyles(styles),
)

export default enhance(CostfitApp);
