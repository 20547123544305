import {CHANGE_ACCOUNT_ID, SET_ACCOUNT_ID} from "../actionTypes";

const initialState = {
    accountId: {},
}

export default function(state = initialState, action) {
    switch (action.type){
        case SET_ACCOUNT_ID: {
            return {
                ...state,
                accountId: action.payload
            }
        }
        case CHANGE_ACCOUNT_ID: {
            const accountId = action.payload;
            return {
                ...state,
                accountId: accountId
            }
        }
        default:
            return state;
    }
}
