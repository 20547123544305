import {combineReducers} from "redux";
import { firebaseReducer } from 'react-redux-firebase'
import {firestoreReducer} from 'redux-firestore'
import loginReducer from './login';
import account from "./account";

export default combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    login: loginReducer,
    account: account,
});
