import React, {useState} from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {connect, useSelector} from 'react-redux';
import {isEmpty, useFirebase} from 'react-redux-firebase';
import {useInput} from "../../hooks/useInput";
import {useLocation, useHistory} from "react-router";
import {Link as RouterLink, Redirect} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import LogoSubtextLight from '../../images/LogoSubtextLight.svg';
import AuthPage from "../../pages/AuthPage";
import {compose} from "recompose";


function SignIn(props) {
    const {classes, auth, profile} = props;
    let history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };
    const firebase = useFirebase();
    const [emailProps, resetEmail] = useInput("");
    const [passwordProps, resetPassword] = useInput("");

    // Configure FirebaseUI.
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: '/signedIn',
        // We will display Google and Facebook as auth providers.
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        ],
    };

    const loginWithEmail = e => {
        e.preventDefault();
        firebase.login({email: emailProps.value, password: passwordProps.value})
            .then(() => {
                const user = firebase.auth().currentUser;
                resetEmail("");
                resetPassword("");
                /*user.sendEmailVerification()
                    .then(() => {
                        console.log('user email verification sent');
                    })
                    .catch(err => {
                        console.error(err);
                    });*/
            }).catch(err => {
                alert(err);
        });

    }

    function loginWithProvider(provider, type= 'popup') {
        //return firebase.login({ provider: provider, type: type })
    }

    /*function sendVerificationEmail(){
        const user = firebase.auth().currentUser;
        user.sendEmailVerification()
            .then(() => {
                console.log('user email verification sent');
            })
            .catch(err => {
                console.error(err);
            });

            {isEmpty(auth)
                            ? <SigninForm />

                            : <><b>{auth.uid}</b>
                                <pre>{JSON.stringify(auth, null, 2)}</pre>
                            </>}
    }*/

    return (
        (!isEmpty(auth) && !isEmpty(profile)) ? (
            <Redirect
                to={{pathname: '/',
                    state: {from: '/login'}}} />
                ) :
            (<AuthPage>
                    <Grid item xs={6} container direction="column" alignItems="center">
                        <Typography component="h2" variant="h2">
                            <img src={LogoSubtextLight} className={classes.signInLogo}/>
                        </Typography>
                        <Typography component="h2" variant="h6">
                            Sign in
                        </Typography>
                        {/*from == '/password' ? (
                            <Typography component="h9" variant="h7">
                                Password reset email was send!
                            </Typography>) : (JSON.stringify(from, null, 2))*/}
                        <form className={classes.form} onSubmit={loginWithEmail} noValidate>
                            <TextField
                                {...emailProps}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                {...passwordProps}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            {/*<FormControlLabel
                        control={<Checkbox value="remember" color="primary"/>}
                        label="Remember me"
                    />*/}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Sign In
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link component={RouterLink} to="/password" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link component={RouterLink} to="/auth/signup" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Divider variant="middle" orientation="vertical" flexItem light={true}/>
                    <Grid item xs={5} container direction="column" alignItems="center">
                        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
                    </Grid>
                </AuthPage>
            )
    );
}

const styles = (theme) => ({
    paper: {
        maxWidth: 736,
        margin: 'auto',
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    contentWrapper: {
        margin: '50px 16px',
    },
    loginWrapper: {
        margin: theme.spacing(2),
        height: '400px',

    },
    fieldWrapper: {
        padding: '8px 8px',
        width: '100%',
    },
    signInLogo: {
        height: 'auto',
        width: '75px'
    },
});

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

const enhance = compose(
    connect(
        // Map redux state to component props
        ({firebase: {auth, profile}}) => ({
            auth,
            profile
        }),
    ),
    withStyles(styles),
)

export default enhance(SignIn);
