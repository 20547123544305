import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CostfitApp from './CostfitApp';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import {ReactReduxFirebaseProvider} from 'react-redux-firebase';
import { BrowserRouter as Router } from "react-router-dom";
import {createFirestoreInstance} from 'redux-firestore';
import { PersistGate } from 'redux-persist/integration/react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import './index.css';
import {store, persistor} from './redux/store'
import CssBaseline from "@material-ui/core/CssBaseline";

const fbConfig = {
    apiKey: "AIzaSyBL8XyLwk6XMEn7WY-MblSGIIeZtNzBQgg",
    authDomain: "my.costfit.app",
    databaseURL: "https://costfit-22545.firebaseio.com",
    projectId: "costfit-22545",
    storageBucket: "costfit-22545.appspot.com",
    messagingSenderId: "822159081923",
    appId: "1:822159081923:web:8f58ac43a4af58a7ddbd76",
    measurementId: "G-CES18KN7VF"
};

const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
    // enableClaims: true // Get custom claims along with the profile
}

// Initialize firebase instance
firebase.initializeApp(fbConfig);
firebase.firestore();

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance // <- needed if using firestore
}

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <ReactReduxFirebaseProvider {...rrfProps}>
                  <Router>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                          <CssBaseline/>
                          <CostfitApp/>
                      </MuiPickersUtilsProvider>
                  </Router>
              </ReactReduxFirebaseProvider>
          </PersistGate>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
