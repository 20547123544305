import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {withStyles} from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import {Link as RouterLink, Redirect} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import LogoSubtextLight from "../../images/LogoSubtextLight.svg";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {useLocation} from "react-router";
import {isEmpty, isLoaded, useFirebase} from "react-redux-firebase";
import {useSelector} from "react-redux";

function PasswordReset(props) {
    const {classes} = props;
    let location = useLocation();
    let {from} = location.state != '/logout' ?{from: "/"} :  location.state || {from: "/"};
    const firebase = useFirebase();
    const auth = useSelector(state => state.firebase.auth);
    const [passwordReset, setPasswordReset] = useState(false);

    let registerSchema = yup.object().shape({
        email: yup.string().email().required(),
    });

    const { register, handleSubmit, errors} = useForm({
        resolver: yupResolver(registerSchema)
    });

    const onSubmit = (values) => {
        //e.preventDefault();
        alert(JSON.stringify(errors, null, 2));
        if (isLoaded(auth) && !isEmpty(auth)) {
            firebase.logout().catch(err => {alert(err);});
        }
        firebase.resetPassword(values.email);
        setPasswordReset(true);
    }

    return (
        passwordReset ? (<Redirect to={{ pathname: '/login', state: { from: '/password' } }}/>) :
            (
        <Container component="main" maxWidth="xs">
            <Grid item xs={12} container direction="column" alignItems="center">
                <Typography component="h2" variant="h2">
                    <img src={LogoSubtextLight} className={classes.siginLogo}/>
                </Typography>
                <Typography component="h5" variant="h5">
                    Password Reset
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                inputRef={register}
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                error={errors.email ? true : false}
                                helperText={errors.email?.message}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Reset Password
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link component={RouterLink} to="/login" variant="body2">
                                Go back to login page
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Container>
            )
    );
}

PasswordReset.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    siginLogo: {
        height: 'auto',
        width: '75px'
    },
});

export default withStyles(styles)(PasswordReset);
