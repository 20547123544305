import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {withStyles} from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import {Link as RouterLink, Redirect} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import LogoSubtextLight from "../../images/LogoSubtextLight.svg";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {isEmpty} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {useLocation} from "react-router";

function SignUp(props) {
    const {classes} = props;
    let location = useLocation();
    let {from} = location.state != '/logout' ?{from: "/"} :  location.state || {from: "/"};
    const auth = useSelector(state => state.firebase.auth);

    let registerSchema = yup.object().shape({
        firstName: yup.string().required("First name is required!"),
        lastName: yup.string().required(),
        email: yup.string().email().required(),
        password: yup.string().min(7, 'Password min length is 8!').required('Password is required')

    });

    const { register, handleSubmit, errors} = useForm({
        resolver: yupResolver(registerSchema)
    });

    const onSubmit = (values) => {
        //e.preventDefault();
        alert(JSON.stringify(errors, null, 2));
    }

    return (
        !isEmpty(auth) ? (<Redirect to={{ pathname: from, state: { from: '/login' } }}/>) :
            (
        <Container component="main" maxWidth="xs">
            <Grid item xs={12} container direction="column" alignItems="center">
                <Typography component="h2" variant="h2">
                    <img src={LogoSubtextLight} className={classes.siginLogo}/>
                </Typography>
                <Typography component="h5" variant="h5">
                    Sign up
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                inputRef={register}
                                autoComplete="fname"
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                error={errors.firstName ? true : false}
                                helperText={errors.firstName?.message}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                inputRef={register}
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="lname"
                                error={errors.lastName ? true : false}
                                helperText={errors.lastName?.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                inputRef={register}
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                error={errors.email ? true : false}
                                helperText={errors.email?.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                inputRef={register}
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                error={errors.password ? true : false}
                                helperText={errors.password?.message}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign Up
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link component={RouterLink} to="/auth/signin" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Container>
                )
    );
}

SignUp.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    siginLogo: {
        height: 'auto',
        width: '75px'
    },
});

export default withStyles(styles)(SignUp);
