import {useSelector} from "react-redux";
import {Redirect, Route} from "react-router-dom";
import {isEmpty, isLoaded} from "react-redux-firebase";
import React from "react";

function PrivateRoute({children, ...rest}) {
    const auth = useSelector(state => state.firebase.auth);
    return (
        <Route
            {...rest}
            render={({location}) =>
                isLoaded(auth) && !isEmpty(auth) ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}

export default PrivateRoute;
