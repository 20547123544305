import {START_LOGIN} from "../actionTypes";
import {isEmpty, isLoaded} from "react-redux-firebase";

export default function(state = {}, action) {
    switch (action.type){
        case START_LOGIN: {
            const auth = state.firebase.auth;
            return {
                ...state,
                showLogin: isLoaded(auth) && isEmpty(auth)
            }
        }
        default:
            return state;
    }
}
