import { createStore } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';

import rootReducer from "./reducers";

const persistConfig = {
    key: 'root',
    storage: localStorage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, composeWithDevTools(
    // other store enhancers if any
));

let persistor = persistStore(store)

export { store, persistor }
