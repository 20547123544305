import React, {useEffect} from 'react';
import {Redirect, useLocation} from "react-router-dom";
import {isEmpty, isLoaded, useFirebase} from "react-redux-firebase";
import {useSelector} from "react-redux";

function Logout() {
    const firebase = useFirebase();
    const auth = useSelector(state => state.firebase.auth);

    useEffect(() => {
        if (isLoaded(auth) && !isEmpty(auth)) {
            firebase.logout().catch(err => {alert(err);});
        }
    });

    return (
        <Redirect
            to={{
                pathname: "/login",
                state: {from: "/logout" }
            }}
        />
    );
}

export default Logout;
