import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useFirebase} from 'react-redux-firebase';
import {useInput} from "../hooks/useInput";
import {useLocation, useHistory} from "react-router";
import {withStyles} from "@material-ui/core/styles";
import AuthHeader from "../components/auth/AuthHeader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Footer from "../components/Footer";

// import GoogleButton from 'react-google-button' // optional

function AuthPage(props) {
    const {classes, userAccount, children} = props;
    let history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: "/" };
    const firebase = useFirebase();
    const auth = useSelector(state => state.firebase.auth);
    const [emailProps, resetEmail] = useInput("");
    const [passwordProps, resetPassword] = useInput("");

    const loginWithGoogle = e => {
        e.preventDefault();
        let loginAuth = firebase.login({email: emailProps.value, password: passwordProps.value});
        resetEmail("");
        resetPassword("");
        return loginAuth;
    }

    return (
        <div className={classes.app}>
            <AuthHeader/>
            <div className={classes.main}>
                <Paper className={classes.paper}>
                    <Grid item xs={12} sm container spacing={2} className={classes.loginWrapper}>
                        {children}
                    </Grid>
                </Paper>
            </div>
            <Footer />
        </div>
    )
}

const drawerWidth = 256;

const styles = (theme) => ({
    paper: {
        maxWidth: 736,
        margin: 'auto',
        minHeight: '475px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    loginWrapper: {
        margin: theme.spacing(2),
    },
    app: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        flex: 1,
        padding: theme.spacing(6, 4),
        background: '#eaeff1',
    },
});

AuthPage.propTypes = {
    classes: PropTypes.object.isRequired,
    //children: PropTypes.object.isRequired
};

export default withStyles(styles)(AuthPage);
